import { Partner, Market } from '@compassdigital/foodworks.api.ts';
import { find } from 'lodash';
import Format from '@/util/Format';
import { IMarketInfo, IMarketScoutInfo } from '@/interface/marketInfo';

export default class MarketUtil {
  /**
   * Parses the markets and returns formatted array
   * @param markets
   */
  public static getMappedMarkets(markets: any) {
    if (!markets) return null;

    const mappedMarkets = !markets || markets.map((market: any) => {
      const {
        id: value,
        name: text,
      } = market;

      return {
        text,
        value,
      };
    });
    return mappedMarkets;
  }

  /**
   * Parses the markets, marketID and returns market name
   * @param markets
   */
  public static getMarketNameByID(markets: any, marketID: string) {
    const market = find(markets, m => m.value === marketID);
    if (market) {
      const { text } = market;
      return text;
    }
    return '';
  }

  /**
   * Parses the scouts, scoutID and returns scout name
   * @param markets
   */
  public static getScoutNameByID(scouts: any, scoutID: string) {
    const scout = find(scouts, m => m.value === scoutID);
    if (scout) {
      const { text } = scout;
      return text;
    }
    return '';
  }

  /**
   * Parses the partners and returns formatted scouts
   * @param partners
   */
  public static getUpdateScouts(partners: Array<Partner>): Array<IMarketScoutInfo> {
    if (!partners) return [];

    const mappedScouts = !partners || partners.map((partner: Partner) => {
      const { scout } = partner;
      const {
        id,
        name,
      } = scout;

      let scoutName = '';
      if (name && name.first && name.last) {
        scoutName = Format.capitalize(`${name.first} ${name.last}`);
      }

      return {
        text: scoutName,
        value: id,
      };
    });
    return mappedScouts.filter((ms: { text: string }) => ms.text !== '');
  }

  /**
   * Parses the markets and returns formatted market Value
   * @param markets
   */
  public static getMappedMarketsValue(markets: any) {
    if (!markets) return null;

    const mappedMarkets = !markets || markets.map((market: any) => {
      const {
        id: value,
      } = market;

      return {
        value,
      };
    });
    return mappedMarkets;
  }

  /**
   * Parses the cuisines and returns formatted array
   * @param cuisines
   */
  public static getMappedCusines(cuisine: any) {
    if (!cuisine) return null;

    const mappedMarkets = !cuisine || cuisine.map((cuisineType: any) => {
      const marketInfo: any = {};
      marketInfo.text = cuisineType;
      marketInfo.value = cuisineType;
      return marketInfo;
    });
    return mappedMarkets;
  }

  /**
   * Parses the sectors, sectorID and returns sector name
   * @param sectors
   */
  public static getSectorNameByID(sectors: any, setorID: string) {
    const market = find(sectors, m => m.id === setorID);
    if (market) {
      const { name } = market;
      return name;
    }
    return '';
  }
}
