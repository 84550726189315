




















































































































































import {
  Vue, Component, Prop, PropSync,
} from 'vue-property-decorator';
import {
  PartnersApi,
  Partner,
  User,
  UserApi,
} from '@compassdigital/foodworks.ts.sdk';
import PartnerUtil from '@/util/PartnerUtil';
import AppModule from '@/store/modules/app';
import UserModule from '@/store/modules/user';
import Format from '@/util/Format';

@Component({
  components: {},
})
export default class UnitRestaurantsList extends Vue {
  @Prop() public search!: string;

  @Prop() public searchCuisine!: string;

  @PropSync('restaurantsList') restaurantsListSync!: Array<Partner>;

  @Prop() public loading!: boolean;

  public errors: string = '';

  private headers = [
    {
      text: 'Restaurant',
      align: 'start',
      value: 'name',
      width: '217px',
    },
    {
      text: 'Address',
      align: 'left',
      value: 'address',
      width: '186px',
    },
    {
      text: 'Cuisine',
      align: 'left',
      value: 'cuisine',
      width: '136px',
    },
    {
      text: 'COI',
      align: 'left',
      value: 'fileCoi',
      width: '250px',

    },
    {
      text: 'Sevr Safe',
      align: 'left',
      value: 'serveSafeFile',
      width: '320px',
    },
  ];

  get filteredItems() {
    let filteredPartners = this.restaurantsListSync;
    if (this.search) {
      filteredPartners = filteredPartners.filter(
        (partner: any) => (partner.name).toLowerCase()
          .includes(this.search.toLowerCase())
        || (partner.address)
          .toLowerCase()
          .includes(this.search.toLowerCase())
        || (partner.cuisine)
          .toLowerCase()
          .includes(this.search.toLowerCase()),
      );
    }
    if (this.searchCuisine && this.searchCuisine.length > 0) {
      filteredPartners = filteredPartners.filter(
        (partner: any) => this.searchCuisine.includes(partner.cuisine),
      );
    }
    return filteredPartners;
  }
}
