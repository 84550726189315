













import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({})
export default class Searchfield extends Vue {
@Prop() public width!: string;
}
