import {
  filter, orderBy, find, compact, differenceWith,
} from 'lodash';
import moment from 'moment';
import {
  UnitsApi, Unit, User, Partner, UserApi, PartnersApi, MarketsApi, Document,
  DocumentRequest,
} from '@compassdigital/foodworks.ts.sdk';
import { IMarketScoutInfo } from '@/interface/marketInfo';
import { IUnitListInformation } from '@/interface/unitInfo';
import { IRestaurantInfo } from '@/interface/schedulerTableInfo';
import MarketUtil from '@/util/MarketUtil';
import Format from '@/util/Format';
import PartnerUtil from '@/util/PartnerUtil';
import AppModule from '@/store/modules/app';

export default class UnitsUtil {
  /**
   * Parses the unit status and returns formatted unit status
   * @param status
   */
  public static getStatus(status: boolean) {
    switch (status) {
      case true:
        return 'Active';
      case false:
        return 'Inactive';
      default:
        return 'Inactive';
    }
  }

  /**
   * Parses the unit status and returns unit status color
   * @param status
   */
  public static getColor(status?: string) {
    switch (status) {
      case 'Active':
        return 'green small';
      case 'Inactive':
        return 'red small';
      default:
        return 'red small';
    }
  }

  /**
   * returns mapped units
   * @param units
   */
  public static getMappedUnits(
    units: Array<Unit>,
    markets: Array<IMarketScoutInfo>,
    scouts: Array<User>,
  ): Array<IUnitListInformation> {
    const mappedUnits = !units || units.map((unit: any) => {
      const {
        id, name, marketId, scoutUserId, active, _createdDate,
      } = unit;
      const unitInfo: IUnitListInformation = {};

      if (markets && marketId) {
        unitInfo.marketName = MarketUtil.getMarketNameByID(markets, marketId);
      }

      if (scouts && scoutUserId) {
        unitInfo.scoutName = MarketUtil.getScoutNameByID(scouts, scoutUserId);
      } else {
        unitInfo.scoutName = '';
      }

      unitInfo.scoutUserID = scoutUserId;
      unitInfo.marketID = marketId;
      unitInfo.unitName = name!;
      unitInfo.id = id;
      unitInfo.createdAt = _createdDate;
      unitInfo.unitStatus = this.getStatus(active);
      unitInfo.unitStatusColor = this.getColor(unitInfo.unitStatus);
      return unitInfo;
    });
    return orderBy(mappedUnits, 'createdAt', 'desc');
  }

  /**
   * searchUnitItems
   * @param items
   * @param search
   */
  public static searchUnitItems(items: Array<IMarketScoutInfo>, search: string) {
    let filteredItems = items;
    if (search) {
      filteredItems = filteredItems.filter(
        units => (units.text!).toString().toLowerCase().includes(search.toString().toLowerCase()),
      );
    }
    return filteredItems;
  }

  /**
   * Parses the users and returns formatted unit manager list
   * @param users
   */
  public static unitManagerListFormat(users: Array<User>) {
    if (!users) return '';
    const activeUsers = !users || users.filter(
      user => user.is?.disabled === false && user.permissions?.scopes.includes('unit_manager'),
    );

    return Format.usersListFormatted(activeUsers);
  }

  /**
   * Parses the users and returns formatted  scoutusers list
   * @param users
   */
  public static scoutListFormatted(users: Array<User>) {
    if (!users) return '';
    const activeUsers = !users || users.filter(
      user => user.is?.disabled === false
        && !user.permissions?.scopes.includes('unit_manager')
        && !user.permissions?.scopes.includes('partner_owner'),
    );
    return Format.usersListFormatted(activeUsers);
  }

  /**
   * mappedDetailsUnit
   * @param units
   * @param markets
   */
  public static async mappedDetailsUnit(
    unit: Unit,
    marketList: IMarketScoutInfo,
    scoutList: IMarketScoutInfo,
    sectorList: IMarketScoutInfo,
  ) {
    if (!unit) return {};
    const {
      id, name, address, scoutUserId, phone, marketId, active, sectorId, managerUserId,
      compassUnitId,
    } = unit;
    let marketName = '';
    if (marketId) {
      marketName = MarketUtil.getMarketNameByID(marketList, marketId as string);
    }
    let marketID: any = '';
    if (marketName) {
      marketID = marketId;
    }

    let scoutDetails: any = '';
    if (scoutUserId) {
      scoutDetails = await this.getScouts(scoutUserId as string);
    }

    let scoutUpdatedID: any = '';
    const { is } = scoutDetails;
    if (is.disabled === false) {
      scoutUpdatedID = scoutUserId;
    }

    let unitUser: any = '';
    if (managerUserId) {
      unitUser = await this.getScouts(managerUserId as string);
    }

    let unitUserID: any = '';
    if (unitUser.is.disabled === false) {
      unitUserID = managerUserId;
    }

    return {
      id,
      compassUnitId,
      name,
      active,
      address,
      sectorId,
      scoutUserId: scoutUpdatedID,
      managerUserId: unitUserID,
      sectorName: MarketUtil.getMarketNameByID(sectorList, sectorId as string),
      marketName,
      marketId: marketID,
      scoutDetails,
      unitUser,
      phone: this.checkNumber(phone!.phone, phone!.ext),
    };
  }

  /**
   * checkNumber
   * @param phone
   */
  public static checkNumber(numberPhone: any, ext: any) {
    const phone: any = {
      phone: '',
      ext: '',
    };
    if (numberPhone) {
      phone.phone = numberPhone;
      phone.ext = ext;
      return phone;
    }
    return phone;
  }

  public static async getScouts(scoutID: string) {
    try {
      const api = new UserApi(AppModule.apiConfig);
      const users = await api.getUser(scoutID);
      const {
        id, phone, email, name, is,
      } = users.data;
      return {
        id,
        phone,
        email,
        is,
        name: Format.getFormattedName(name),
      };
    } catch (err) {
      console.log('errors', err.response.data);
      throw err;
    }
  }

  public static getMappedUnitId(unitId: string) {
    return this.getUnitList(unitId);
  }

  public static async getUnitList(unitId: string) {
    try {
      const api = new UnitsApi(AppModule.apiConfig);
      const units: any = await api.getsUnits();
      const unitsList = units.data;
      const unitMappedId = unitsList.map((unit: any) => {
        const { id, managerUserId } = unit;
        return {
          text: id,
          value: managerUserId,
        };
      });
      return this.getUnitManagerID(unitMappedId, unitId);
    } catch (err) {
      throw err;
    }
  }

  /**
   * Parses the units id list, unit manager id and returns unitID
   * @param unitId
   */
  public static getUnitManagerID(units: any, unitID: string) {
    const unit = find(units, m => m.value === unitID);
    if (unit) {
      const { text } = unit;
      return text;
    }
    return '';
  }

  public static getMappedPartnerList() {
    return this.getPartnerList();
  }

  public static async getPartnerList() {
    try {
      const api = new PartnersApi(AppModule.apiConfig);
      const units: any = await api.getPartners();
      const unitsList = units.data;
      return unitsList;
      // return this.getUnitManagerID(unitMappedId, unitId);
    } catch (err) {
      throw err;
    }
  }

  /**
   * Parses the units users and returns active units users
   * @param users
   */
  public static activeUnits(users: Array<User>) {
    if (!users) return '';
    const activeUnits = !users || users.filter(
      (user: any) => user.active === true,
    );
    return this.getMappedUnitNames(activeUnits);
  }

  /**
   * returns mapped units
   * @param units
   */
  public static getMappedUnitNames(units: any) {
    const mappedUnits = !units || units.map((unit: any) => {
      const {
        id, name,
      } = unit;
      const unitInfo: any = {};

      unitInfo.unitName = name!;
      unitInfo.id = id;
      return unitInfo;
    });
    return orderBy(mappedUnits, 'unitName', 'asc');
  }

  /**
   * returns mapped events
   * @param events
   */
  public static getMappedEvents(events: any) {
    if (!events) return '';
    const mappedEvents = !events || events.filter((event: any) => {
      const { type } = event;
      if (type === 'non-working') {
        return event;
      }
      return '';
    });

    const nonWorkingEvent = !mappedEvents || mappedEvents.map((event: any) => {
      const {
        id, date, meta, status, type, unitId, _createdDate,
      } = event;
      const eventInfo: any = {};
      eventInfo.id = id;
      eventInfo.date = date;
      eventInfo.meta = meta;
      eventInfo.status = status;
      eventInfo.type = type;
      eventInfo.unitId = unitId;
      eventInfo.createdDate = _createdDate;
      if (date) {
        eventInfo.formattedDateEvent = this.getEventDateFormatted(date);
      }
      return eventInfo;
    });
    return orderBy(nonWorkingEvent, 'createdDate', 'asc');
  }

  /**
   * Parses the date and returns formatted date
   * @param eventDate
   */
  public static getEventDateFormatted(eventDate: any) {
    return moment(eventDate).format('ddd, MMM. Do, YYYY');
  }

  public static getMappedUnitList() {
    return this.getUnitUsers();
  }

  /**
   * returns mapped units
   * @param units
   */
  public static async getUnitUsers() {
    try {
      const api = new UnitsApi(AppModule.apiConfig);
      const units: any = await api.getsUnits();
      const unitsList = units.data;
      return unitsList;
    } catch (err) {
      throw err;
    }
  }

  /**
   * returns mapped restaurant exclusion
   */
  public static async getUnitRestaurants(unitID: string) {
    try {
      const api = new UnitsApi(AppModule.apiConfig);
      const units: any = await api.getUnitById(unitID);
      const { schedule: { restaurantExclusions } } = units.data;
      if (restaurantExclusions.length > 0) {
        return restaurantExclusions;
      }
      return null;
    } catch (err) {
      throw err;
    }
  }

  /**
   * returns mapped cuisine exclusion
   */
  public static async getUnitRestaurantsCuisine(unitID: string) {
    try {
      const api = new UnitsApi(AppModule.apiConfig);
      const units: any = await api.getUnitById(unitID);
      const { schedule: { cuisineExclusions } } = units.data;
      if (cuisineExclusions.length > 0) {
        return cuisineExclusions;
      }
      return null;
    } catch (err) {
      throw err;
    }
  }


  /**
   * returns mapped available restaurant
   */
  public static async getAvailableRest(exludedRest: Array<Unit>, availbaleRest: any) {
    try {
      const restaurantAv = !exludedRest || exludedRest.map((rest) => {
        const filterRest = !availbaleRest
          || availbaleRest.filter((restFilter: any) => {
            if (restFilter.id.includes(rest)) {
              // eslint-disable-next-line no-param-reassign
              restFilter.isExcludedRest = true;
              // eslint-disable-next-line no-param-reassign
              restFilter.availableRest = false;
              return restFilter;
            }
            return null;
          });
        return filterRest[0];
      });
      return compact(restaurantAv);
    } catch (error) {
      throw error;
    }
  }

  /**
   * returns mapped available restaurant with excluded cuisine
   */
  public static async getExcludedCuisine(cuisines: Array<Unit>, availbaleRest: any) {
    try {
      const restaurantAv = !cuisines || cuisines.map((cuisine) => {
        const filterRest = !availbaleRest
          || availbaleRest.filter((restFilter: any) => {
            if (restFilter.cuisneType === cuisine) {
              // eslint-disable-next-line no-param-reassign
              restFilter.isExcludedCuisine = true;
              // eslint-disable-next-line no-param-reassign
              restFilter.availableRest = false;
              return restFilter;
            }
            return null;
          });
        return filterRest[0];
      });
      return compact(restaurantAv);
    } catch (error) {
      throw error;
    }
  }

  /**
   * returns mapped exclude restaurant
   */
  public static async getExcludedVal(
    result1: Array<IRestaurantInfo>, result2: Array<IRestaurantInfo>,
  ) {
    try {
      // Find values that are in result1 but not in result2
      const uniqueResultOne = result1.filter(
        (obj1) => !result2.some((obj2: any) => obj1.id === obj2.id),
      );

      // Find values that are in result2 but not in result1
      const uniqueResultTwo = result2.filter(
        (obj1) => !result1.some((obj2) => obj1.id === obj2.id),
      );

      const result = uniqueResultOne.concat(uniqueResultTwo);
      return result;
    } catch (error) {
      throw error;
    }
  }

  /**
   * Parses the partners users and returns active partners users
   * @param users
   */
  public static activePartners(users: Array<User>) {
    if (!users) return '';
    const activePartners = !users || users.filter((user: any) => {
      const { active, onboarding: { status } } = user;
      if (active === true && status === 'completed') {
        return user;
      }
      return '';
    });
    return this.getMappedPartnerUsers(activePartners);
  }

  /**
   * Parses the partner Users and returns formatted parrtner name and id
   * @param units
   */
  public static getMappedPartnerUsers(partners: any) {
    const mappedPartner = !partners || partners.map((partner: any) => {
      const {
        id, name,
      } = partner;
      const partnerInfo: any = {};

      partnerInfo.text = name!;
      partnerInfo.value = id;
      return partnerInfo;
    });
    return orderBy(mappedPartner, 'text', 'asc');
  }

  /**
   * Parses the partner Users and returns formatted parrtner name
   * @param units
   */
  public static formattedIdPartners(partners: any) {
    if (!partners) return '';
    const mappedID = !partners || partners.map((item: any) => {
      const partnerInfo = {
        text: item.name,
        value: item.id,
      };
      return partnerInfo;
    });
    return mappedID;
  }

  /**
   * Parses the partner Users and returns formatted parrtner name
   * @param units
   */
  public static getPartnersListName(restaurantExclusions: any, restaurants: any) {
    const users = !restaurantExclusions || restaurantExclusions.map((item: any) => {
      if (item && restaurants) {
        const restName = MarketUtil.getMarketNameByID(restaurants, item);
        return restName;
      }
      return '';
    });
    return users;
  }

  public static getMarketList() {
    return this.getAllMarkets();
  }

  public static async getAllMarkets() {
    try {
      const api = new MarketsApi(AppModule.apiConfig);
      const markets: any = await api.getMarkets();
      const marketList = markets.data;
      return marketList;
      // return this.getUnitManagerID(unitMappedId, unitId);
    } catch (err) {
      throw err;
    }
  }

  /**
   * Parses the partners, markets and returns formatted array
   */
  public static getMappedRestaurants(restaurants: Array<Partner>) {
    const mappedPartners = !restaurants
      || restaurants.map(async (partner: any) => {
        const {
          name,
          ownerUserId,
          _createdDate,
          id,
          cuisineTypes,
          address: {
            address,
          },
        } = partner;
        const partnerInfo: any = {};
        partnerInfo.cuisine = Format.getFormatCuisine(cuisineTypes);
        partnerInfo.id = id;
        partnerInfo.name = name;
        partnerInfo.createdAt = _createdDate;
        partnerInfo.address = address;
        if (id) {
          const files = await this.getDocuments(id as string);
          const fileCoiType = !files || files.filter((doc: any) => doc.type === 'insurance');
          const fileServeType = !files || files.filter((doc: any) => doc.type === 'serve-safe');
          if (fileCoiType) {
            partnerInfo.fileCoi = this.getFileType(fileCoiType);
          }
          if (fileServeType) {
            partnerInfo.serveSafeFile = this.getFileType(fileServeType);
          }
        }
        return partnerInfo;
      });
    return Promise.all(orderBy(mappedPartners, 'createdAt', 'desc'));
  }

  public static async getDocuments(id: string) {
    try {
      const api = new PartnersApi(AppModule.apiConfig);
      const docs = await api.getPartnerDocuments(id);
      const { documents } = docs.data;
      return documents;
    } catch (error) {
      return error;
      console.log('error', error);
    }
  }

  public static getFileType(file: Array<Document>) {
    let fileNum = 0;
    const files = !file || file.map((doc: Document) => {
      const { id, documentName, url } = doc;
      const fileData: any = {};
      fileData.id = id;
      fileNum += 1;
      fileData.FileValue = fileNum;
      fileData.fileURl = url;
      const typeOfFile = documentName?.split('.').reverse()[0];
      if (typeOfFile) {
        fileData.fileType = typeOfFile;
      }
      return fileData;
    });
    return files;
  }

  public static formatImages(images: Array<DocumentRequest>) {
    const mappedImages = !images || images.map((doc: any) => {
      const { url: path } = doc;
      return {
        path,
      };
    });
    return mappedImages;
  }

  public static getMappedUnitIdRestaurant() {
    return this.getUnitListRes();
  }

  public static async getUnitListRes() {
    try {
      const api = new UnitsApi(AppModule.apiConfig);
      const units: any = await api.getsUnits();
      const unitsList = units.data[0];
      const { id } = unitsList;
      return id;
    } catch (err) {
      throw err;
    }
  }
}
