






import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class PageTitle extends Vue {
  @Prop({}) pageTitle!: string;

  @Prop({}) public marginBottom!: string;

  @Prop({}) public marginTop!: string;
}
