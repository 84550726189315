import {
  Module, VuexModule, getModule, Mutation, Action,
} from '@compassdigital/vuex-module-decorators';
import {
  Partner, PartnersApi, User, UserApi,
} from '@compassdigital/foodworks.ts.sdk';
import store from '@/store';
import AppModule from '@/store/modules/app';

interface IPartnerModule {
  activePartner: Partner
}
@Module({
  dynamic: true,
  name: 'partner',
  namespaced: true,
  store,
})
class PartnerModule extends VuexModule implements IPartnerModule {
  public activePartner: Partner = {}

  public userLists: User[] = [];

  public restaurantStatus: string = '';

  public restaurantList: Partner = {};

  @Mutation
  public SET_ACTIVE_PARTNER(partner: Partner) {
    this.activePartner = partner;
  }

  @Mutation
  public SET_RESTAURANT_STATUS(status: string) {
    this.restaurantStatus = status;
  }

  @Mutation
  public SET_RESTAURANT_LIST(restaurants: Partner) {
    this.restaurantList = restaurants;
  }

  /**
   * Fetch the restaurant profile
   * @param restaurantID
   */
  @Action
  public async fetchRestaurantsProfile(restaurantID: string) {
    const api = new PartnersApi(AppModule.apiConfig);
    const restaurants = await api.getPartnerById(restaurantID);
    const partner: any = restaurants.data;
    const { status } = partner.onboarding;
    this.SET_RESTAURANT_STATUS(status as string);
  }

  /**
   * Fetch the restaurant profile list
   */
  @Action
  public async fetchRestaurantList() {
    const api = new PartnersApi(AppModule.apiConfig);
    const restaurants = await api.getPartners();
    const restaurantUsers = restaurants.data;
    this.SET_RESTAURANT_LIST(restaurantUsers as Partner);
  }
}
export default getModule(PartnerModule);
