// mixin.js
import Vue from 'vue';
import Component from 'vue-class-component';
import { FWAccessControl } from '../util/AccessControlUtil';

// You can declare a mixin as the same style as components.
@Component
export default class PermissionMixin extends Vue {
  hasCreate(resource: string, attribute?: string): boolean {
    return FWAccessControl.hasCreate(resource, attribute);
  }

  hasRead(resource: string, attribute?: string): boolean {
    return FWAccessControl.hasRead(resource, attribute);
  }

  hasUpdate(resource: string, attribute?: string): boolean {
    return FWAccessControl.hasUpdate(resource, attribute);
  }

  hasDelete(resource: string, attribute?: string): boolean {
    return FWAccessControl.hasDelete(resource, attribute);
  }
}
