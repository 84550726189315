























































































import { Vue, Component } from 'vue-property-decorator';
import { SettingsApi, Settings, Partner } from '@compassdigital/foodworks.ts.sdk';
import { mixins } from 'vue-class-component';
import PageTitle from '@/components/PageTitle/index.vue';
import Searchfield from '@/components/Form/Searchfield/index.vue';
import UnitRestaurantsList from '@/components/Table/UnitRestaurantsList/index.vue';
import AppModule from '@/store/modules/app';
import PartnerModule from '@/store/modules/partner';
import MarketUtil from '@/util/MarketUtil';
import UnitsUtil from '@/util/UnitUtil';
import PermissionMixin from '../../mixins/PermissionMixin';

@Component({
  components: {
    PageTitle,
    Searchfield,
    UnitRestaurantsList,
  },
})
export default class unitRestaurants extends mixins(PermissionMixin) {
  private search: string = '';

  private cuisinesArray: Settings [] = [];

  private cuisineCheck: string = '';

  private searchCuisine = '';

  private restaurantsList: Partner [] = [];

  public loading: boolean = false;

  get cuisineItemsFiltered() {
    let filteredItems = this.cuisinesArray;
    if (this.cuisineCheck) {
      filteredItems = filteredItems.filter(item => Object.values(item)
        .join(',')
        .toLowerCase()
        .includes(this.cuisineCheck.toLowerCase()));
    }
    return filteredItems;
  }

  public onCuisine(data: any) {
    this.updateQuery(JSON.stringify(data), 'cuisine');
  }

  public updateQuery(data: any, key: string) {
    this.$router.replace({
      query: {
        ...this.$route.query,
        [key]: data,
      },
    });
  }

  public resetQuery() {
    this.$router.replace({
      query: {},
    });
  }

  public clearSearchAll() {
    this.searchCuisine = '';
    this.search = '';
  }

  public mounted() {
    if (this.$route.query) {
      const {
        cuisine,
      } = this.$route.query;
      if (cuisine) {
        this.searchCuisine = JSON.parse((cuisine as any));
      }
    }
    this.getCuisinesArray();
    this.getPartners();
  }

  public async getPartners() {
    try {
      this.loading = true;
      await PartnerModule.fetchRestaurantList();
      const usersList: Partner = PartnerModule.restaurantList;
      const users = await UnitsUtil.getMappedRestaurants(usersList as any);
      this.restaurantsList = users;
    } catch (err) {
      throw err;
    }
    this.loading = false;
  }

  public async getCuisinesArray() {
    const api = new SettingsApi(AppModule.apiConfig);
    const cuisine: any = await api.getSettings();
    const { cuisineTypes } = cuisine.data;
    const cuisinesArrays = cuisineTypes.sort((a:any, b:any) => a.localeCompare(b, 'en', { sensitivity: 'base' }));
    this.cuisinesArray = MarketUtil.getMappedCusines(cuisinesArrays);
  }
}
